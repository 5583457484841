import { Business } from "@menufy/models";
import { Nullable } from "@eqn/data-types";

export interface BusinessDetailState {
  business: Nullable<Business>;
  success: boolean;
  loading: boolean;
  error: unknown;
}

export const initialBusinessDetail: BusinessDetailState = {
  business: null,
  success: false,
  loading: false,
  error: null,
};
