import {
  GetBusinessDetailFailedPropsType,
  GetBusinessDetailPropsType,
  GetBusinessDetailSuccessPropsType,
} from "../actions";

const getBusinessDetail = (
  state: GetBusinessDetailPropsType,
  action: GetBusinessDetailPropsType
) => ({
  ...state,
  business: action.business,
  success: false,
  loading: true,
});

const getBusinessDetailSuccess = (
  state: GetBusinessDetailPropsType,
  action: GetBusinessDetailSuccessPropsType
) => ({
  ...state,
  business: { ...action },
  success: true,
  loading: false,
  error: null,
});

const getBusinessDetailFailed = (
  state: GetBusinessDetailPropsType,
  action: GetBusinessDetailFailedPropsType
) => ({
  ...state,
  business: null,
  success: false,
  loading: false,
  error: action.error,
});

export default {
  getBusinessDetail,
  getBusinessDetailSuccess,
  getBusinessDetailFailed,
};
