import { BusinessDetailActions } from "./actions/business-detail.actions";
import { createFeature, createReducer, on } from "@ngrx/store";
import { initialBusinessDetail } from "./business-detail.states";
import businessDetailReducers from "./reducers/business-detail.reducers";

const BUSINESS_DETAIL_FEATURE_NAME = "businessDetail";

export const businessDetailFeature = createFeature({
  name: BUSINESS_DETAIL_FEATURE_NAME,
  reducer: createReducer(
    initialBusinessDetail,
    on(BusinessDetailActions.getBusinessDetail, businessDetailReducers.getBusinessDetail),
    on(
      BusinessDetailActions.getBusinessDetailSuccess,
      businessDetailReducers.getBusinessDetailSuccess
    ),
    on(
      BusinessDetailActions.getBusinessDetailFailed,
      businessDetailReducers.getBusinessDetailFailed
    )
  ),
});

export const businessDetailSlice = {
  name: businessDetailFeature.name,
  reducer: businessDetailFeature.reducer,
};
