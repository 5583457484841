import { BusinessDetailState } from "../business-detail.states";
import { Business } from "@menufy/models";
import { createActionGroup, props } from "@ngrx/store";

export type GetBusinessDetailPropsType = BusinessDetailState;
export type GetBusinessDetailSuccessPropsType = Business;
export type GetBusinessDetailFailedPropsType = { error: unknown };

export const BusinessDetailActions = createActionGroup({
  source: "Business/Detail",
  events: {
    getBusinessDetail: props<GetBusinessDetailPropsType>(),
    getBusinessDetailSuccess: props<GetBusinessDetailSuccessPropsType>(),
    getBusinessDetailFailed: props<GetBusinessDetailFailedPropsType>(),
  },
});
